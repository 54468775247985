import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RestService} from "../../core/services/rest.service";
import {Pessoa} from "../../../classes/pessoa";
import {Fiscal} from "../../../classes/fiscal";
import {FiscalFilter} from "../filters/fiscal.filter";

@Injectable()
export class FiscalService {

  constructor(private restService: RestService) {
  }

  buscar(fiscalFilter: FiscalFilter): Observable<Fiscal> {
    return this.restService.post("/fiscal/buscar", {data: fiscalFilter, responseType: Fiscal});
  }

  buscarListaPessoaPorMunicipio(fiscalFilter?: FiscalFilter): Observable<Pessoa[]> {
    return this.restService.post("/fiscal/buscarListaPessoaPorMunicipio", {data: fiscalFilter, responseType: Pessoa});
  }
}
