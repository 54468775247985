import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "../core/auth/auth.guard";
import {LandingPageComponent} from "./components/landing/landing.page.component";

const routes: Routes = [

  {path: "home", canActivate: [AuthGuard], loadChildren: "../../modules/home/home.module#HomeModule"},

  {path: "landing-page", component: LandingPageComponent},

  {path: "public", loadChildren: "../public/public.module#PublicModule"},

  {path: "", redirectTo: "home", pathMatch: "full", canActivate: [AuthGuard]},

  {path: "**", redirectTo: "home/not-found", pathMatch: "full"}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],

  declarations: [],

  exports: [
    RouterModule
  ],

  providers: []
})

export class AppRoutingModule {
}
