import {Component, EventEmitter, Injector, Input, Output} from "@angular/core";
import {BaseComponent} from "../base/base.component";
import {DateUtilsService} from "../../../core/services/date.utils.service";

@Component({
  selector: 'date-range',
  templateUrl: './date.range.component.html',
  styleUrls: ['./date.range.component.scss'],
})
export class DateRangeComponent extends BaseComponent {

  @Input()
  clearTime: boolean = true;

  @Input()
  displayFormat: string = "dd/MM/yyyy";

  @Input()
  label: string = "até";

  get lower(): Date {
    return this._lower;
  }

  @Input()
  set lower(value: Date) {
    this._lower = this.clearTime && value ? this.dateUtils.clearTime(value, true) : value;

    this.minRange = this._lower ? this._lower : null;

    this.lowerChange.emit(this._lower);
  }

  @Input()
  maxZoomLevel: string;

  @Input()
  minZoomLevel: string;

  @Input()
  showClearButton: boolean = true;

  @Input()
  type: string = 'date';

  get upper(): Date {
    return this._upper;
  }

  @Input()
  set upper(value: Date) {
    this._upper = this.clearTime && value ? this.dateUtils.clearTime(value, false) : value;

    this.maxRange = this._upper ? this._upper : null;

    this.upperChange.emit(this._upper);
  }


  useMaskBehavior: boolean = true;

  @Input()
  readOnly: boolean = false;

  @Output()
  lowerChange: EventEmitter<Date> = new EventEmitter<Date>();

  @Output()
  upperChange: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(private dateUtils: DateUtilsService,
              private inject: Injector) {
    super(inject);
  }

  maxRange: Date;

  minRange: Date;

  _lower: Date;

  _upper: Date;
}
