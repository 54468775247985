export enum TipoSolicitacaoEnum {

  AbatimentoObra = "Solicitação de abatimento de obra na base de cálculo da NFe",
  AIDF = "AIDF",
  AlteracaoNFe = "Carta de Correção de NF-e",
  ANFE = "Autorização para NF-e",
  AutenticacaoLivroFiscal = "Autenticação de Livro Fiscal",
  Cadastro = "Cadastro",
  CadastroEventual = "Solicitação de Cadastro",
  CancelamentoEncerramento = "Cancelamento de Encerramento de Escrituração",
  CancelamentoNotaFiscal = "Cancelamento de Notas Fiscais",
  Generica = "Solicitação",
  IMPORTACAO_NFE = "Autorização de Emissão de Recibo Provisório de Serviços",
  PerfilDescontoNotaFiscal = "Solicitação de desconto da base de cálculo da NFe"
}

export function enviaEmail(tipoSolicitacaoEnum: TipoSolicitacaoEnum): boolean {

  if (tipoSolicitacaoEnum == TipoSolicitacaoEnum.AbatimentoObra) {
    return true;
  } else if (tipoSolicitacaoEnum == TipoSolicitacaoEnum.AIDF) {
    return false;
  } else if (tipoSolicitacaoEnum == TipoSolicitacaoEnum.AlteracaoNFe) {
    return false;
  } else if (tipoSolicitacaoEnum == TipoSolicitacaoEnum.ANFE) {
    return false;
  } else if (tipoSolicitacaoEnum == TipoSolicitacaoEnum.AutenticacaoLivroFiscal) {
    return true;
  } else if (tipoSolicitacaoEnum == TipoSolicitacaoEnum.Cadastro) {
    return true;
  } else if (tipoSolicitacaoEnum == TipoSolicitacaoEnum.CadastroEventual) {
    return true;
  } else if (tipoSolicitacaoEnum == TipoSolicitacaoEnum.CancelamentoEncerramento) {
    return false;
  } else if (tipoSolicitacaoEnum == TipoSolicitacaoEnum.CancelamentoNotaFiscal) {
    return false;
  } else if (tipoSolicitacaoEnum == TipoSolicitacaoEnum.Generica) {
    return false;
  } else if (tipoSolicitacaoEnum == TipoSolicitacaoEnum.IMPORTACAO_NFE) {
    return false;
  } else if (tipoSolicitacaoEnum == TipoSolicitacaoEnum.PerfilDescontoNotaFiscal) {
    return true;
  } else {
    return false;
  }
}
