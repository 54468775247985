export class EnumUtils {

  public static getKey(type, currentValue): string {
    for (let key in type) {
      if (type[key] == currentValue) {
        return key;
      }
    }

    return null;
  }

  public static valueOf(type, currentValue): any {
    for (let key in type) {
      if (type[key] == currentValue) {
        return type[key];
      }
    }

    return null;
  }

  public static keys(type) {
    const output = [];

    for (let key in type) {
      if (typeof type[key] === 'string') {
        output.push(key);
      }
    }

    return output;
  }

  public static values(type) {
    const output = [];

    for (let key in type) {
      if (typeof type[key] === 'string') {
        output.push(type[key]);
      }
    }

    return output;
  }

  public static valueOfByKey(type, targetKey) {
    for (let key in type) {
      if (key == targetKey) {
        return type[key];
      }
    }

    return null;
  }

  public static ordinal<T>(enumType: T, enumValue: any): number {
    if (!enumType || !enumValue) {
      return null;
    }

    return Object.values(enumType).findIndex(e => e == enumValue);
  };
}
